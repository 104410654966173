<template>
    <div class="atmos-cube shake-slow" v-if="visible" :style="cssVars">
<!--        <div v-if="posz * -1 < fallOffDist" class="pane back"></div>-->
        <div ref="topPane" v-if="posz * -1 < fallOffDist || !topHidden" class="pane top" :class="darkMode? 'dark-mode' : ''"></div>
        <div ref="bottomPane" v-if="posz * -1 < fallOffDist || !bottomHidden" class="pane bottom" :class="darkMode? 'dark-mode' : ''"></div>
        <div ref="leftPane" v-if="posz * -1 < fallOffDist || !leftHidden" class="pane left" :class="darkMode? 'dark-mode' : ''"></div>
        <div ref="rightPane" v-if="posz * -1 < fallOffDist || !rightHidden" class="pane right" :class="darkMode? 'dark-mode' : ''"></div>
        <div class="pane front" :class="darkMode? 'dark-mode' : ''"></div>
    </div>
</template>

<script>
    import {ref} from "vue";
    export default {
        name: "AtmosCube",
        props: ['posx', 'posy', 'posz', 'rotx', 'roty', 'rotz', 'colorBase', 'blurAmount', 'startZ', 'darkMode'],
        data: function () {
            return {
                visible: true,
                isActive: false,
                fallOffDist: 10000,
                closeFallOffDist: 1000,
                perspective: 0,
                topHidden: false,
                bottomHidden: false,
                leftHidden: false,
                rightHidden: false,
            }
        },
        setup() {
            const topPane = ref('topPane')
            const bottomPane = ref('bottomPane')
            const leftPane = ref('leftPane')
            const rightPane = ref('rightPane')
            return {topPane, bottomPane, leftPane, rightPane}
        },
        computed: {
            cssVars() {
                return {
                    '--z-index': this.calculateOutput(this.posz * -1),
                    //'--display': this.posz < this.closeFallOffDist ? 'block' : 'none',
                    '--pos-X': this.posx + "vw",
                    '--pos-Y': this.posy + "vh",
                    '--pos-Z': this.posz + 'px',
                    '--rot-X': this.rotx + "deg",
                    '--rot-Y': this.roty + "deg",
                    '--rot-Z': this.rotz + "deg",
                    '--rand-anim-x': this.randInt() + "px",
                    '--rand-anim-y': this.randInt() + "px",
                    '--rand-anim-rot-x': (this.rotx + (this.randInt() * 0.1)) + "deg",
                    '--rand-anim-rot-y': (this.roty + (this.randInt() * 0.1)) + "deg",
                    '--rand-anim-rot-z': (this.rotz + (this.randInt() * 0.1)) + "deg",
                    '--rand-time': this.randTime() + "s",
                    '--color-base': 'rgb(' + this.colorBase[0] + ',' + this.colorBase[1] + ',' + this.colorBase[2] + ')',
                    '--color-semi-dark': 'rgb(' + (this.colorBase[0] - 25) + ',' + (this.colorBase[1] - 25) + ',' + (this.colorBase[2] - 25) + ')',
                    '--color-dark': 'rgb(' + (this.colorBase[0] - 60) + ',' + (this.colorBase[1] - 60) + ',' + (this.colorBase[2] - 60) + ')',
                    '--color-light': 'rgb(' + (this.colorBase[0] + 70) + ',' + (this.colorBase[1] + 70) + ',' + (this.colorBase[2] + 70) + ')',
                    //'--blur-amount': this.blurAmount * 30 + "px",
                    '--outline': this.blurAmount > 0.2 ? 'none' : '3px solid black',
                    '--opacity': this.blurAmount > 0.4 ? 1 - this.blurAmount : 1,
                }
            }
        },
        methods: {
            calculateOutput: function(x) {
                let min = this.$parent.$data.maxZDist * -1
                let max = 600
                let normalizedX = ((x - min) / (max - min)) * 100
                return Math.floor(200 / normalizedX * 1000);
            },
            randInt: function() {
                var flipInt = Math.random()
                if(flipInt > 0.5) {
                    return Math.floor(Math.random() * 50) + 1
                } else {
                    return -(Math.floor(Math.random() * 50) + 1)
                }
            },
            randTime: function() {
                return Math.floor(Math.random() * 20) + 5
            },
            observerCallback: function(entries, observer) {
                console.log(entries, observer)
            }
            // isBehindOtherElement: function(element) {
            //     if (!element) return null;
            //     const boundingRect = element.getBoundingClientRect()
            //     const left = boundingRect.left + 1
            //     const right = boundingRect.right - 1
            //     const top = boundingRect.top + 1
            //     const bottom = boundingRect.bottom - 1
            //
            //     if(document.elementFromPoint(left, top) !== element) return true
            //     if(document.elementFromPoint(right, top) !== element) return true
            //     if(document.elementFromPoint(left, bottom) !== element) return true
            //     if(document.elementFromPoint(right, bottom) !== element) return true
            //
            //     return false
            // }
        },
        mounted() {
            // this.topHidden = this.isBehindOtherElement(this.$refs.topPane)
            // this.bottomHidden = this.isBehindOtherElement(this.$refs.bottomPane)
            // this.leftHidden = this.isBehindOtherElement(this.$refs.leftPane)
            // this.rightHidden = this.isBehindOtherElement(this.$refs.rightPane)

            var options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            }

            // eslint-disable-next-line no-unused-vars
            var observer = new IntersectionObserver(this.observerCallback, options)
        },
        watch: {
            posz: function(val) {
                this.visible = (val < 500 || (this.blurAmount > 0.4 ? 1 - this.blurAmount : 1) > 0.01)
            },
        }
    }

</script>

<style scoped>
    .atmos-cube {
        display: var(--display);
        z-index: var(--z-index);
        position: absolute;
        width: 200px;
        height: 200px;
        -webkit-transform-style: preserve-3d;
        transform-origin: center center 0;
        transform-style: preserve-3d;
        transform:
            translate3d(var(--pos-X), var(--pos-Y), var(--pos-Z))
            rotateX(var(--rot-X))
            rotateY(var(--rot-Y))
            rotateZ(var(--rot-Z));
        transition: all 0.8s cubic-bezier(0.34, 1.30, 0.64, 1);
    }

    .pane {
        opacity: var(--opacity);
        z-index:1;
        outline-offset: -3px;
        transform-style: preserve-3d;
        text-align: center;
        display:block;
        position: absolute;
        width: 200px;
        height: 200px;
        background-color: #2b7862;
        transition: all 0.5s;
        /*outline: var(--outline);*/
        /*box-shadow: 0 0 40px rgba(0, 0, 0, 0.25) inset;*/
    }

    .atmos-cube .pane.dark-mode {
        filter: brightness(0.8);
    }
/*
    .shake-slow {
      animation-name:shake-slow;
      animation-duration: var(--rand-time);
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }


    @keyframes shake-slow {
      0%{
        transform:
            translate3d(
                var(--pos-X),
                var(--pos-Y),
                var(--pos-Z)
            )
            rotateX(var(--rot-X))
            rotateY(var(--rot-Y))
            rotateZ(var(--rot-Z))
      }
      50%{
        transform:
            translate3d(
                calc(var(--pos-X) - var(--rand-anim-x)),
                calc(var(--pos-Y) - var(--rand-anim-y)),
                var(--pos-Z)
            )
            rotateX(var(--rand-anim-rot-x))
            rotateY(var(--rand-anim-rot-y))
            rotateZ(var(--rand-anim-rot-z))
      }
      100%{
        transform:
            translate3d(
                var(--pos-X),
                var(--pos-Y),
                var(--pos-Z)
            )
            rotateX(var(--rot-X))
            rotateY(var(--rot-Y))
            rotateZ(var(--rot-Z))
      }
    }

    .front::after {
      content: '';
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background: linear-gradient(to bottom right, var(--color-light), var(--color-dark));
      opacity: 0.7;
    }

    .left::after {
      content: '';
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background: linear-gradient(to bottom, var(--color-light), var(--color-dark));
      opacity: 0.7;
    }*/

    .front { -webkit-transform: translateZ(100px); background-color: var(--color-base);}
    /*.back { -webkit-transform: rotateY(180deg) translateZ(100px); background-color: var(--color-dark);display:none; }*/
    .top { -webkit-transform: rotateX(90deg) translateZ(100px); background-color: var(--color-light); }
    .bottom { -webkit-transform: rotateX(-90deg) translateZ(100px); background-color: var(--color-dark); }
    .left { -webkit-transform: rotateY(-90deg) translateZ(100px); background-color: var(--color-semi-dark); }
    .right { -webkit-transform: rotateY(90deg) translateZ(100px); background-color: var(--color-semi-dark); }
</style>